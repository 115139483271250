//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import applyButton from "@/lib/data-service/haolv-default/consumer_menu_applyButton";
import myApplyList from "@/lib/data-service/haolv-default/consumer_apply_myApplyList";
import repealApply from "@/lib/data-service/haolv-default/consumer_apply_repealApply";
import consumer_apply_workflowMyApplyList from '@/lib/data-service/haolv-default/consumer_apply_workflowMyApplyList';
import moment from "moment"
export default {
  name: "Apply",
  data() {
    return {
      tableLoad: false,
      totalPage: 0,
      options: [
        {
          value: "",
          label: "全部"
        },
        {
          value: 1,
          label: "出差申请"
        },
        {
          value: 2,
          label: "超标申请"
        },
        {
          value: 7,
          label: "审批申请"
        },
        {
          value: 4,
          label: "改签申请",
        },
        {
          value: 5,
          label: "退订申请",
        },
      ],
      formData: {
        pageSize: 9,
        currentPage: 1,
        date: [],
        approveType: "",
        selectType: 2,
        searchValue: ""
      },
      jurisdiction: false,   // 按钮权限范围
      tableData: []
    };
  },
  methods: {
    getTimeDay(startDate,endDate){
      if (startDate != null && endDate != null) {
        return (new Date(endDate)-new Date(startDate))/(1000*60*60*24)
      }
    },
    goInfo(val) {
        console.log(val);
      this.$router.push({
        name: "admin-my-apply-manage-apply-info",
        query: { applyId: val.id, type: "info", applyNo: val.applyNo, approveType: val.approveType, appType: 1, selectType: this.formData.selectType, approveStatus: val.approveStatus, lastMenu: 'adminMyApplyParticipation' }
      });
    },
    apply() {
      // this.$router.push({ name: "admin-my-apply-manage-apply-form" });
      this.$router.push({ name: "admin-add-evection" });
    },
    expense() {
      this.$router.push({ name: "admin-my-apply-manage-expense-apply" });
    },
    revocation(val) {
      this.$confirm("确定要撤销吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          repealApply({ applyNo: val.applyNo }).then(res => {
            this.$message({ type: "success", message: "撤销成功！" });
            this.getList();
          });
        })
        .catch(() => {});
    },
    edit() {
      this.$router.push({ name: "admin-my-apply-manage-apply-form" });
    },
    handleCurrentChange(currPage) {
      this.formData.currentPage = currPage;
      this.getList();
    },
    handleSizeChange(val) {
      this.formData.pageSize = pageSize;
      this.getList();
    },
    // 出差申请按钮状态
    async getApplyButton() {
      try {
        const res = await applyButton()
        this.jurisdiction = res.datas.jurisdiction
      } catch (error) {

      }
    },
    getList() {
      this.tableLoad = true;
      let data = Object.assign({}, this.formData);
      data.date && data.date.length > 0
        ? ((data.startDateTime = data.date[0]),
          (data.endDateTime = data.date[1]))
        : "";
      let applyApi = this.$store.state.workflow === '1' ? consumer_apply_workflowMyApplyList(data) : myApplyList(data);
      applyApi.then(res => {
        let list = res.datas.list;
        list.forEach((item) => {
          if (item.recordList && item.recordList.length) {
            const record = item.recordList[0];
            item.lastNodeApprover = record.approverUserName;
            item.lastNodeTime = moment(record.gmtCreate).format(
              "MM-DD hh:mm"
            );
            switch (record.approveStatus) {
              case 0:
                item.lastNodeStatus = "未开始";
                break;
              case 1:
                item.lastNodeStatus = "待审批";
                break;
              case 2:
                item.lastNodeStatus = "已审批通过";
                break;
              case 3:
                item.lastNodeStatus = "已审批拒绝";
                break;
              case 4:
                item.lastNodeStatus = "已失效";
                break;
            }
          } else {
            item.lastNodeApprover = `发起人：${item.applyName}`;
            item.lastNodeStatus = "";
            item.lastNodeTime = "";
          }
        });
        this.tableData = list;
        this.totalPage = res.datas.totalCount;
        this.tableLoad = false;
      });
    }
  },
  created() {

  },
  async activated() {
    this.getApplyButton();
    await this.$store.state.workflowDefer.promise;
    this.getList();
  },
  watch: {},
  computed: {}
};
